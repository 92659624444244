<template>
    <div></div>
</template>

<script>

export default {

    data() {
        return {
            initialized: false,
            sounds: [
                { name: 'beep', sound: '/sound/beep.mp3'},            
                { name: 'blip', sound: '/sound/blip.mp3'},        
                { name: 'winner', sound: '/sound/travel_winner.mp3'},        
                { name: 'pointPlus', sound: '/sound/travel_plus_point.mp3'},            
                { name: 'pointMinus', sound: '/sound/travel_minus_point.mp3'},   
                { name: 'lotteryWin', sound: '/sound/lottery_win.mp3'},
                { name: 'lotteryLoose', sound: '/sound/lottery_loose.mp3'},
            ],
        }
    },    
    methods: {
        init: function() {
            if (!this.initialized) {
                console.log('init sounds: ' + this.sounds.length); 
                this.sounds.forEach(s => {
                    window.createjs?.Sound.registerSound(s.sound, s.name);                    
                });
                this.initialized = true;
            }
        },
        play: function(name, count) {
            if (this.initialized && name) {
                const _count = Number.isNaN(count) ? 1 : count;
                this._playAudio(name, _count, 250);                
            }
        },
        _playAudio: function(name, count, delay) {
            window.createjs?.Sound.play(name);
            if (--count > 0) {  // play sound multiple times (not working on iPhone...?)
                setTimeout(() => {
                    this._playAudio(name, count, delay);
                }, delay);                    
            }             
        }        
    }
}

</script> 