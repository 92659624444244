<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal">
            <div class="modal-content max-w-xl sm:my-2">

                <div class="flex justify-between">
                    <div class="font-semibold">
                        <span v-if="add">Add new Question</span>
                        <span v-else-if="edit">Edit Question</span>
                        <span v-else-if="approve">Approve Question</span>
                    </div>                    
                    <svg class="h-10 w-10 cursor-pointer -mt-2" viewBox="0 0 24 24" @click="close()">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                                        
                </div>

                <div class="text-sm mb-2">Author: {{ form.authorName }}</div>

                <label>Category</label>
                <select class="w-full" v-model="form.categoryId">
                    <option v-for="(category, i) in categories" :key="i" :value="category.id">{{ category.name }}</option>
                </select>        

                <label>Question</label>
                <textarea v-model="form.question" class="w-full h-24 resize-none" :disabled="limitedEdit"></textarea>
                
                <div class="float-right underline cursor-pointer" @click="addNewPicture()">New picture</div>

                <label class="w-full">Picture / Audio / Video</label>
                <input type="search" placeholder="Search" @search="searchMedia" class="w-full">                
                <div class="mt-1 mb-3 overflow-auto"> 
                    <span v-for="(m,i) in mediaSearchResult" :key="i" class="mr-3 cursor-pointer _w-full whitespace-nowrap" @click="selectMedia(m)" v-html="formatMediaSearch(m)"></span>
                </div>

                <div v-if="form.pictureId" class="mb-4">
                    <div class="flex flex-wrap justify-center">
                        <div>
                            <MaskedPicture v-if="form.pictureMaskGridX > 0" :imgUrl="getMediaUrl(form.pictureId)" :gridX="form.pictureMaskGridX*1" :key="maskedPicKey" />
                            <img v-else :src="getMediaUrl(form.pictureId)" class="max-h-64">                                                
                            <div>{{ form.mediaAttribution }}</div>
                        </div>

                        <div class="flex gap-x-4 sm:gap-x-5 items-center mt-1">
                            <div @click="form.pictureId=0" class="cursor-pointer">[Remove picture]</div>
                            <div class="flex items-center">
                                <label class="mr-1">Mask</label>
                                <select v-model="form.pictureMaskGridX" @change="maskedPicKey++" style="padding-bottom: 0; margin-bottom: 0" :disabled="form.pictureTime > 0"> 
                                    <option value="0">None</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>                        
                            </div>  
                            <div class="flex items-center">
                                <label class="mr-1">Timer</label>
                                <select v-model="form.pictureTime" style="padding-bottom: 0; margin-bottom: 0" :disabled="form.pictureMaskGridX > 0">
                                    <option value="0">None</option>
                                    <option value="5">5 sec</option>
                                    <option value="10">10 sec</option>
                                    <option value="15">15 sec</option>
                                </select>
                            </div>                                             
                        </div>
                    </div>
                </div>                
                <div v-if="form.audioId" class="w-full mb-4">
                    <div class="flex flex-wrap items-center">
                        <audio controls class="mr-2">
                            <source :src="getMediaUrl(form.audioId)">
                        </audio>
                        <div @click="form.audioId=0" class="cursor-pointer">[Remove audio]</div>
                    </div>
                </div>
                <div v-if="form.videoId" class="w-full mb-4">
                    <div class="flex flex-wrap">
                        <video controls class="mr-2">
                            <source :src="getMediaUrl(form.videoId)">
                        </video>
                        <div @click="form.videoId=0" class="cursor-pointer">[Remove video]</div>
                    </div>
                </div>                

                <div>
                    <label for="answer">Answer</label>
                    <div>
                        <input type="radio" v-model="answerType" value="1" name="answtype" id="answ1" @click="answerType=1" :disabled="limitedEdit" checked ><label for="answ1" class="ml-1">Standard</label>
                        &nbsp;
                        <input type="radio" v-model="answerType" value="2" name="answtype" id="answ2" @click="answerType=2" :disabled="limitedEdit"><label for="answ2" class="ml-1">Options</label>
                        &nbsp;
                        <input type="radio" v-model="answerType" value="3" name="answtype" id="answ3" @click="answerType=3" :disabled="limitedEdit"><label for="answ3" class="ml-1">Closest</label>
                    </div>
                </div>

                <div v-if="hasOptions">
                    <div v-for="(opt,i) in optionCount" :key="i" class="mb-1 w-full">            
                        <input :ref="'option'+opt" class="w-5/6" :disabled="limitedEdit"> <input type="radio" v-model="optionAnswer" :value="opt" :disabled="limitedEdit">
                    </div>        
                </div>
                <div v-else-if="hasClosest">
                    <span class="w-20 inline-block">Answer:</span><input v-model="form.answer" type="number" :disabled="limitedEdit" class="w-32" placeholder="Answer">                    
                    &nbsp;<input type="text" ref="unit" class="w-20" style="margin-bottom: 0 !important" placeholder="Unit">
                    <div v-for="(opt, a) in closestAges" :key="a" :value="a" class="my-2 flex items-center">
                        <div class="w-20">{{ opt }}:</div>
                        <input type="number" :ref="'closestFrom_' + a" :disabled="limitedEdit" placeholder="From" class="w-32">
                        -<input type="number" :ref="'closestTo_' + a" :disabled="limitedEdit" placeholder="To" class="w-32">
                    </div>                                                                    
                </div>
                <div v-else>
                    <input v-model="form.answer" class="w-full" :disabled="limitedEdit">
                </div>
                    
                <div class="flex justify-between items-baseline">
                    <div class="flex items-baseline" v-if="!hasClosest">
                        <label class="w-12">Age</label>
                        <select v-model="form.ageGroup">
                            <option v-for="(opt, o) in ages" :key="o" :value="o">{{ opt }}</option>
                        </select>
                    </div>        
                    <div>
                        <input type="checkbox" id="norway" v-model="form.norway">
                        <label for="norway" class="ml-1">Norway only</label>
                    </div>
                </div>


                <div class="flex items-baseline">
                    <label class="w-12">Date</label>
                    <input type="date" v-model="form.date">
                </div>   

                <div class="flex items-baseline">
                    <label class="w-12">Start</label>
                    <input type="date" v-model="form.start">
                </div>

                <div class="flex items-baseline">
                    <label class="w-12">Expire</label>
                    <input type="date" v-model="form.expire">
                </div>     

                <div class="text-center mt-2 text-lg sm:text-xl">
                    <button class="btn w-full sm:w-min" @click="submitQuestion()">
                        <span v-if="add">Add</span>                        
                        <span v-else-if="edit">Save</span>
                        <span v-else-if="approve">Approve</span>
                    </button>   
                </div>

            </div>
        </div>
    </teleport>

    <MediaModal ref="mediaModal" :edit="true" />
</template>

<script>
import axios from 'axios';
import { htmlDate, formatDateTime } from '@/utils.js';
import MediaModal from '@/components/MediaModal.vue';
import MaskedPicture from '@/components/MaskedPicture.vue';

export default {
    components: {
        MediaModal,
        MaskedPicture
    },
    props: {
        title: Text,
        add: Boolean,
        edit: Boolean,
        approve: Boolean,
    },
    data() {
        return {
            show: false,
            answerType: 1,
            optionCount: 4,
            optionAnswer: -1,
            categories: [],
            form: {
                id: 0,
                categoryId: 0,
                question: '',
                answer: '',       
                ageGroup: 3,      
                norway: false,   
                created: '',
                date: '',
                start: '',
                expire: '',
                authorId: this.$store.getters.author.id,
                approvedBy: 0,
                pictureId: 0,
                audioId: 0,
                videoId: 0,            
                mediaAttribution: '',   
            },
            mediaSearchResult: [],            
            maskedPicKey: 0,
            resolve: null,
            reject: null,
            ages: ["All", "10-13", "13-16", "16+"]
        }
    },
    computed: {
        hasOptions() {
            return this.answerType == 2;
        },
        hasClosest() {
            return this.answerType == 3;
        },
        limitedEdit() {
            return this.form.approved;
        },
        closestAges() {
            return this.ages.slice(1);
        },
        isTravelCommon() {
            return this.form.categoryId == process.env.VUE_APP_TRAVEL_COMMON_ID;
        },
    },
    created() {
        this.getCategories();  
    },
    methods: {
        open: function(question) {
            console.log(question);
            this.clearForm();            
            this.show = true;   
            if (question) {                
                this.getQuestion(question);
            }      
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });                           
        },
        close: function() {
            this.show = false;
            this.resolve();
        },
        getCategories: function() {
            axios.get('/question/categories').then(res => {
                this.categories = res.data;
            });
        },
        getQuestion: function(question) {
            const id = question.id * question.showInAppIdConst;
            axios.get('/question/' + id).then(res => {
                this.form = res.data;                     
                if (res.data.options.length) {
                    this.answerType = this.isTravelCommon ? 3 : 2;
                    this.$nextTick(() => { // wait for options input to render
                        if (this.isTravelCommon) {
                            res.data.options.forEach((ageGroup, idx) => {
                                this.$refs['closestFrom_' + idx][0].value = ageGroup[0];
                                this.$refs['closestTo_' + idx][0].value = ageGroup[1];
                                this.$refs['unit'].value = ageGroup[2] || '';
                            });
                        }
                        else {                            
                            for (let i = 1; i <= res.data.options.length; i++) {                            
                                this.$refs['option'+i][0].value = res.data.options[i-1];
                            }       
                            this.optionAnswer = res.data.answer*1+1;                                         
                        }
                    });
                }                
                this.form.norway = res.data.country === 'no';
                this.form.created = formatDateTime(this.form.created);
                if (this.form.approved) {
                    this.form.approved = formatDateTime(this.form.approved);
                }
                this.form.date = htmlDate(this.form.date);
                this.form.start = htmlDate(this.form.start);
                this.form.expire = htmlDate(this.form.expire);                                       
            });
        },
        isPicture: function(mimeType) {
            return mimeType && mimeType.startsWith('image');
        },
        isAudio: function(mimeType) {
            return mimeType && mimeType.startsWith('audio');
        }, 
        isVideo: function(mimeType) {
            return mimeType && mimeType.startsWith('video');
        },    
        addNewPicture: function() {
            this.$refs.mediaModal.newPicture();
        },           
        searchMedia: function(e) { 
            const s = e.target.value;
            if (s.length > 1) {
                axios.get('/media/search?s=' + s).then(res => {                
                    this.mediaSearchResult = res.data;
                    if (!this.mediaSearchResult.length) {
                        this.mediaSearchResult.push({ keywords:'No hit!' });
                    }
                });
            }
        },
        formatMediaSearch: function(media) {
            let s = '';
            if (this.isPicture(media.mimeType)) {
                s = '🖼️';
            }
            else if (this.isAudio(media.mimeType)) {
                s = '🔊';
            }
            else if (this.isVideo(media.mimeType)) {
                s = '🎞️';
            }            
            return s + ' ' + media.keywords;
        },
        selectMedia: function(media) {        
            if (this.isPicture(media.mimeType)) {
                this.form.pictureId = media.id;
            }
            else if (this.isAudio(media.mimeType)) {
                this.form.audioId = media.id;
            }
            else if (this.isVideo(media.mimeType)) {
                this.form.videoId = media.id;
            }            
        },
        getMediaUrl: function(mediaId) {
            const now = new Date();
            return process.env.VUE_APP_API + '/question/media/' + mediaId + '?_=' + now.getTime();
        },
        clearForm: function() {
            this.answerType = 1;
            this.optionAnswer = -1;
            this.form.question = '';
            this.form.answer = '';
            this.form.norway = false;
            this.form.date = '';
            this.form.start = '';
            this.form.expire = '';
            this.form.pictureId = 0;
            this.form.audioId = 0;
            this.form.videoId = 0;
            this.form.pictureMaskGridX = 0;
            this.form.pictureTime = 0;
            this.mediaSearchResult = [];
        },
        submitQuestion: function() {
            const data = this.form;

            if (!data.categoryId) {
                alert('Select a category');
                return;
            }
            if (!data.question) {
                alert('Provide a question');
                return;
            }

            if (this.hasOptions) {                
                data.options = [];
                for (let i = 1; i <= this.optionCount; i++) {
                    const opt = this.$refs['option'+i][0].value;
                    if (opt) {
                        data.options.push(opt);
                    }
                }
                data.answer = this.optionAnswer-1;  // override with correct answer option index
                if (data.options.length < 2) {
                    alert('Provide more options');
                    return;
                }
                if (data.answer < 0 || data.answer > data.options.length-1) {
                    alert('Select the correct answer');
                    return;
                }
            }   
            else if (this.hasClosest) {
                data.options = [];   
                const unit = this.$refs['unit'].value;
                this.closestAges.forEach((ages, idx) => {
                    const from = this.$refs['closestFrom_' + idx][0].value;
                    const to = this.$refs['closestTo_' + idx][0].value;                    
                    data.options.push([Number(from), Number(to), unit]);
                });
                data.ageGroup = 0;  // these questions are for all ages
            }
            else {
                delete data.options;
            }         

            if (data.answer.length == 0) {
                alert('Provide an answer');
                return;
            }
                        
            data.country = data.norway ? 'no' : '';

            let endpoint = 'add';
            if (this.edit) {
                endpoint = 'edit';
            }
            if (this.approve) {
                endpoint = 'approve';
                data.approvedBy = this.$store.getters.author.id;
            }
            
            axios.post('/question/' + endpoint, data).then(res => { 
                const questionId = this.form.id || res.data.id;
                console.log('id', questionId);   
                this.close();                          
            }).catch(() => {
                alert('Error occurred! Please try again..');
            });
        },   
    }
}

</script>

<style scoped>

input:not([type=search]):not([type=number]), textarea, select {
    @apply mb-4;
}

</style>