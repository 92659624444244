<template>
    <div>        
        <div class="flex">  
            <div class="rounded text-white text-sm">
                <div v-if="q.approved" class="bg-green-500 px-1">
                    Approved
                </div>
                <div v-else class="bg-yellow-500 px-1">
                    Pending approval
                </div>
            </div>
            <div class="underline cursor-pointer ml-4 flex flex-wrap gap-x-3">
                <div @click="sendEvent('edit', q)">Edit</div>
                <div v-if="q.approved" class="flex gap-x-3 sm:flex-row flex-col">                    
                    <div @click="sendEvent('un-approve', q.id)">Un-approve</div>
                    <div v-if="category && toDate(q.approved.date) > toDate(category.versionDate)">
                        <div  class="bg-purple-200 px-1" @click="sendEvent('set-approve-date', q.id, category.versionDate)" title="Move to current category version">Include</div>
                    </div>
                </div>
                <div v-else>                    
                    <div @click="sendEvent('delete', q.id)">Delete</div>
                </div>                
            </div>
        </div>
        
        <div class="font-semibold text-sm mb-1">
            Age: {{ getAge(q.ageGroup) }} <span v-if="q.country">| Country: {{ q.country.toUpperCase() }}</span>
        </div>  
        <div v-if="showCategory" class="inline bg-gray-200 px-1 text-sm">{{ q.categoryName }}</div>
        <div class="whitespace-pre-wrap" v-html="q.question"></div>
        <div class="flex cursor-pointer mb-1">
            <div v-if="q.pictureId" class="text-sm border border-gray-600 px-2 mr-2" @click="sendEvent('open-picture', q.pictureId, q.mediaAttribution)">
                Picture <span v-if="q.pictureMaskGridX">(masked)</span><span v-else-if="q.pictureTime">({{q.pictureTime}} sec)</span>
            </div>
            <div v-if="q.audioId" class="text-sm border border-gray-600 px-2 mr-2" @click="sendEvent('open-audio', q.audioId)">Audio</div>        
            <div v-if="q.videoId" class="text-sm border border-gray-600 px-2" @click="sendEvent('open-video', q.videoId)">Video</div>                                      
        </div>        
        <div v-if="q.options.length">
            <div v-if="(q.options[0] instanceof Array)">
                Answer: {{ q.answer }}
                <div class="mt-1 font-semibold text-sm">Ranges</div>
                <div v-for="(ageGroup, a) in q.options" :key="a" class="flex">
                    <div class="w-16">{{ getAge(a+1) }}:</div><div>{{ ageGroup[0] }}</div><div class="mx-1">-</div><div>{{ ageGroup[1] }}</div>&nbsp;<div>{{ ageGroup[2] }}</div>
                </div>                
            </div>
            <div v-else>
                <div class="font-semibold text-sm">Options</div>
                <div v-for="(opt,i) in q.options" :key="i">                        
                    <span class="font-semibold mr-1" :class="i == q.answer ? 'text-green-500' : 'text-white'">X</span>{{ opt }}
                </div>
            </div>
        </div>
        <div v-else>
            <span class="font-semibold">Answer:</span> {{ q.answer }}
        </div>
        <div class="flex gap-x-5 mt-2 text-blue-900">
            <div class="cursor-pointer flex gap-x-0.5 items-center" @click="showInApp(q, true)" title="Show in app (as player)">
                <fa-icon icon="mobile" /><fa-icon icon="user" />                
            </div>
            <div class="cursor-pointer flex gap-x-0.5 items-center" @click="showInApp(q, false)" title="Show in app (as others)">
                <fa-icon icon="mobile" /><fa-icon icon="users" />
            </div>
            <div @click="copyShowInAppUrl(q)" class="text-sm underline cursor-pointer">
                Copy link
            </div>
        </div>
        <div class="text-sm mt-1 italic">
            <span v-if="q.authorName">Author: {{ q.authorName }} |</span>
            Created: {{ formatDateTime(q.created) }}
            <span v-if="q.approved"> | Approved: {{ formatDateTime(q.approved) }}</span>
            <span v-if="q.start"> | Start: {{ formatDate(q.start) }}</span>
            <span v-if="q.expire"> | Expire: {{ formatDate(q.expire) }}</span>
            <span v-if="q.date"> | Date: {{ formatDate(q.date) }}</span>
        </div>
    </div>
</template>

<script>
import { formatDate, formatDateTime, toDate } from '@/utils.js';

export default {
    props: {
        q: Object,
        showCategory: Boolean,
        category: Object,
    },
    emits: [
        'edit', 'delete', 'un-approve', 'set-approve-date', 'open-picture', 'open-audio', 'open-video'
    ],
    methods: {
        formatDateTime, formatDate, toDate,
        getAge: function(ageGroup) {
            switch (ageGroup) {
                case 0: return 'All'; 
                case 1: return '10-13'; 
                case 2: return '13-16'; 
                case 3: return '16+'; 
                default: return '';
            }
        },        
        sendEvent: function(eventName, id, data) {        
            this.$emit(eventName, id, data);  
        },
        getShowInAppUrl: function(question, asPlayer) {
            const id = question.id * question.showInAppIdConst;
            const domain = location.host.includes('localhost') ? '' : 'https://ainou.no';
            return domain + '/game?uuid=' + process.env.VUE_APP_SHOW_IN_APP_UUID + '&qid=' + id + '&player=' + asPlayer;
        },
        copyShowInAppUrl: function(question) {
            const url = this.getShowInAppUrl(question, false);
            navigator.clipboard.writeText(url);
        },
        showInApp: function(question, asPlayer) {
            const url = this.getShowInAppUrl(question, asPlayer);
            window.open(url);
        }
    }
}
</script>