<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">

            <div class="modal-content text-center">                
                <div class="modal-header">Velg tidsgrense for spørsmål</div>
                <div> 
                    <div class="text-sm">Tid man kan bruke per spørsmål</div>
                    <div v-if="init" class="mb-3 text-sm">Kan endres underveis i spillet</div>                        

                    <div class="mt-2 flex flex-col place-items-center">
                        <div v-for="(time, t) in questionTimers" :key="t" class="text-left py-1 w-1/3 whitespace-nowrap">
                            <label class="text-lg"><input type="radio" name="timer" :value="time" :checked="time === questionTime" @change="setQuestionTime"> {{ questionTimeLabel(time) }}</label>
                        </div>
                    </div>
                </div>

                <div v-if="travel" class="mt-4">
                    <hr>
                    <div class="modal-header">Velg spillets varighet</div>
                    <div class="text-sm mb-2">Spillet avsluttes automatisk når tiden er ute</div>
                    <div>
                        <input type="range" v-model="gameDuration" :disabled="!init" min="10" max="120" step="5" class="slider w-full" @change="setGameDuration">
                        <div class="text-lg">{{ formatGameDuration(gameDuration) }}</div>
                    </div>
                    
                    <!--div v-if="init">                 
                        <hr>   
                        <div class="modal-header">Årstid</div>
                        <div class="text-sm">Velg hvilken side av spillebrettet som brukes</div>
                        <div class="mt-4 flex gap-x-4 justify-center">
                            <div class="seasonBtn" :class="{'selected summer': season === 'summer'}" @click="selectSeason('summer')">Sommer</div>
                            <div class="seasonBtn" :class="{'selected winter': season === 'winter'}" @click="selectSeason('winter')">Vinter</div>
                        </div>
                    </div-->
                </div>

                <hr>
                <div class="modal-footer flex justify-evenly">
                    <button class="btn w-20 py-2" @click="close()">OK</button>
                </div>                
            </div>
        </div>
    </teleport>
</template>

<script> 
import { questionTimeLabel } from '@/utils.js';

export default {
    props: {
        travel: Boolean,
        questionTime: Number,
    },
    emits: [
        'set-question-time',
        'set-travel-game-time',
        'set-travel-game-season',
    ],
    data() {
        return {
            questionTimers: [0, 60, 120],
            show: false,
            init: false,
            season: '',
            gameDuration: 30,
            //summerMonths: [5, 6, 7, 8, 9, 10],
            resolve: null,
            reject: null,
        }
    },
    methods: {
        questionTimeLabel,
        open: function(init) {
            this.init = init;
            this.show = true;
            //this.autoSelectSeason();
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });             
        },
        close: function() {
            this.show = false;
            this.resolve();
        },
        setQuestionTime: function(e) {            
            this.$emit('set-question-time', e.target.value);
        },
        setGameDuration: function(e) {            
            this.$emit('set-travel-game-time', e.target.value);
        },
        /*selectSeason: function(season) {
            this.season = season;
            this.$emit('set-travel-game-season', season);
        },*/
        formatGameDuration: function(duration) {
            const hour = Math.floor(duration/60);            
            const min = duration % 60;
            let hourMin = hour > 0 ? hour + 't ' : '';
            hourMin += min > 0 ? min + 'min' : '';
            return hourMin;
        },
        /*autoSelectSeason: function() {
            const d = new Date();
            const month = d.getMonth()+1;            
            const season = this.summerMonths.includes(month) ? 'summer' : 'winter';
            this.selectSeason(season);
        }*/
    }

}

</script>

<style scoped>
.seasonBtn {
    @apply w-32 px-4 py-2 border-2 text-gray-500 font-semibold rounded-lg;
}
.seasonBtn.selected {
    @apply text-white;
}
.seasonBtn.selected.summer {
    @apply bg-green-600;
}
.seasonBtn.selected.winter {
    @apply bg-blue-400;
}
hr {
    @apply pt-4 mt-4;
}
</style>