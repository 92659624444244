import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faPhone, faUser, faFlag, faCog, faReply, faClock, faTrash, faPaperPlane, faUserFriends, faHistory } from "@fortawesome/free-solid-svg-icons";
import { faCode, faSpellCheck, faListAlt, faMailBulk, faHouseUser, faShoppingCart, faEdit, faFileAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { faList, faCheck, faSync, faSms, faBuilding, faAt, faKeyboard, faReceipt, faExclamationTriangle, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faMobile, faUsers, faPencilAlt, faCopy, faPlaneDeparture, faGift, faLink, faCartPlus } from "@fortawesome/free-solid-svg-icons";

library.add(faPhone);
library.add(faUser);
library.add(faFlag);
library.add(faCog);
library.add(faReply);
library.add(faClock);
library.add(faTrash);
library.add(faPaperPlane);
library.add(faUserFriends);
library.add(faHistory);
library.add(faCode);
library.add(faSpellCheck);
library.add(faListAlt);
library.add(faMailBulk);
library.add(faHouseUser);
library.add(faShoppingCart);
library.add(faEdit);
library.add(faFileAlt);
library.add(faSave);
library.add(faSync);
library.add(faCheck);
library.add(faList);
library.add(faSms);
library.add(faBuilding);
library.add(faAt);
library.add(faKeyboard);
library.add(faReceipt);
library.add(faExclamationTriangle);
library.add(faFileExcel);
library.add(faMobile);
library.add(faUsers);
library.add(faPencilAlt);
library.add(faCopy);
library.add(faPlaneDeparture);
library.add(faGift);
library.add(faLink);
library.add(faCartPlus);

export default FontAwesomeIcon;